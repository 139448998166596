/* eslint-disable */
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { faEye, faEyeSlash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Amplify } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  confirmSignIn, forgotPassword, forgotPasswordSubmit, signIn,
} from '../api/AuthAPI';
import './LoginSignupPage.css';
import { getAudience, checkInvitation, respondInvitation } from '../api/UserAPI';
import { resetState } from '../redux/userInfo';
import { capitalizeFirstLetter, validateEmail } from './Update';
import randomString from '../utils/utils';

const PAGES = {
  LOGIN: 'login',
  SMS_MFA: 'sms_mfa',
  SOFTWARE_TOKEN_MFA: 'software_token_mfa',
  FORGOT_PASSWORD: 'forgot_password',
  FORGOT_PASSWORD_SUBMIT: 'forgot_password_submit',
  INVITATION: 'invitation',
};

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    pageToDisplay: PAGES.LOGIN,
  });

  const [mfa, setMFA] = useState({});
  const [verCode, setVerCode] = useState(0);
  const [deliveryNum, setDeliveryNum] = useState(0);
  const [checked, setChecked] = useState(localStorage.getItem('email') ? true : false);
  const [signInState, setSignInState] = useState({
    userId: '', attributes: '', mfaConfigured: '', rememberDevice: '', phoneState: false,
  });

  const [loginState, setLoginState] = useState({
    email: localStorage.getItem('email') ? localStorage.getItem('email') : '',
    password: '',
  });

  const [invitationsArray, setInvitationsArray] = useState({});

  const [forgotPasswordState, setForgotPasswordState] = useState({
    email: '',
    code: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  // Error Messages
  const [signInError, setSignInError] = useState(false);
  const [signInErrorMsg, setSignInErrorMsg] = useState('');
  const [sendEmailError, setSendEmailError] = useState(false);
  const [sendEmailErrorMsg, setSendEmailErrorMsg] = useState('');
  const [submitPassError, setSubmitPassError] = useState(false);
  const [submitPassErrorMsg, setSubmitPassErrorMsg] = useState('');
  const [mfaError, setMfaError] = useState(false);
  const [mfaErrorMsg, setMfaErrorMsg] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const signInResponse = await signIn(loginState.email.toLowerCase(), loginState.password);

    // If Error
    if (signInResponse.error) {
      if (signInResponse.error.message !== null) {
        setSignInError(true);
        console.error('error message:', signInResponse.error?.message);
        console.log('error code', signInResponse.error?.code);
        if (signInResponse.error.message === 'Username cannot be empty') {
          setSignInErrorMsg('Please enter a valid email address.');
        } else if (
          signInResponse.error?.code === 'InvalidParameterException'
          || signInResponse.error?.code === 'UserNotFoundException'
          || signInResponse.error?.code === 'NotAuthorizedException') {
          setSignInErrorMsg('Not Authorized \n Incorrect username or password.');
        } else if (signInResponse.error.code === 'UserNotConfirmedException') {
          navigate('/signup', { state: { hide: true, email: loginState.email } });
        } else {
          setSignInErrorMsg(signInResponse.error.message);
        }
      }

      // If Multi-factor Authentication
    } else if (signInResponse.mfa) {
      setMFA(signInResponse.mfa);

      if (signInResponse.mfa.challengeName === 'SMS_MFA') {
        const number = signInResponse.mfa.challengeParam.CODE_DELIVERY_DESTINATION;
        setDeliveryNum(number.substring(1));
        updatePageToDisplay(PAGES.SMS_MFA);
      } else if (signInResponse.mfa.challengeName === 'SOFTWARE_TOKEN_MFA') {
        updatePageToDisplay(PAGES.SOFTWARE_TOKEN_MFA);
      }

      // If Password-based Authentication
    } else {
      AWS.config.credentials = { ...signInResponse.credentials, region: 'us-east-1' };
      AWS.config.region = 'us-east-1';

      // configure pubsub
      const userId = signInResponse.credentials.identityId.split(':')[1];
      Amplify.addPluggable(new AWSIoTProvider({
        aws_pubsub_region: process.env.REACT_APP_REGION,
        aws_pubsub_endpoint: process.env.REACT_APP_IOT_ENDPOINT,
        clientId: userId + randomString(10),
      }));
      localStorage.setItem('userId', userId);

      console.log('login successful');
      const userExistence = await getAudience();
      localStorage.setItem('audience', userExistence?.user?.audience ?? 'swidget');
      localStorage.setItem('phoneNumber', userExistence?.user?.phoneNumber);
      const phoneState = userExistence?.user?.phoneNumberVerified;
      const currentUser = {firstName: userExistence?.user?.firstName, lastName: userExistence?.user?.lastName, email: userExistence?.user?.email}
      if (checked === true) {
        localStorage.setItem('email', loginState.email);
      } else {
        if (loginState.email === localStorage.getItem('email'))
          localStorage.removeItem('email');
      }
      const response = await checkInvitation();
      const pendingInvitaitons = response.invitations.filter((invitaion) => invitaion.status === 'pending');
      if (pendingInvitaitons.length > 0) {
        setInvitationsArray(pendingInvitaitons);
        setSignInState({
          userId,
          attributes: signInResponse.attributes,
          mfaConfigured: signInResponse.mfaConfigured,
          rememberDevice: signInResponse.rememberDevice,
          phoneState,
        });
        updatePageToDisplay(PAGES.INVITATION);
      } else {
        dispatch(resetState(''));
        navigate('/home', {
          replace: true,
          state: {
            userId,
            attributes: signInResponse.attributes,
            mfaConfigured: signInResponse.mfaConfigured,
            rememberDevice: signInResponse.rememberDevice,
            phoneState,
            currentUser: currentUser,
          },
        });
      }
    }
  };

  const handleSubmitMFA = async (event) => {
    event.preventDefault();

    const signInResponse = await confirmSignIn(mfa, verCode);

    if (signInResponse.error) {
      setMfaError(true);
      console.log(signInResponse.error.message);
      if (signInResponse.error.message === 'Confirmation code cannot be empty') {
        setMfaErrorMsg('Verification code cannot be empty.');
      } else {
        setMfaErrorMsg('Login Error - Invalid code received for user.');
      }
    } else {
      AWS.config.credentials = { ...signInResponse.credentials, region: 'us-east-1' };
      AWS.config.region = 'us-east-1';

      // configure pubsub
      const userId = signInResponse.credentials.identityId.split(':')[1];
      Amplify.addPluggable(new AWSIoTProvider({
        aws_pubsub_region: process.env.REACT_APP_REGION,
        aws_pubsub_endpoint: process.env.REACT_APP_IOT_ENDPOINT,
        clientId: userId + randomString(10),
      }));
      localStorage.setItem('userId', userId);

      const userExistence = await getAudience();
      localStorage.setItem('audience', userExistence?.user?.audience ?? 'swidget');
      localStorage.setItem('phoneNumber', userExistence?.user?.phoneNumber);
      const phoneState = userExistence?.user?.phoneNumberVerified;
      // get attributes
      const attributes = mfa?.signInUserSession?.idToken?.payload ?? null;

      console.log('login successful');
      navigate('/home', {
        replace: true,
        state: {
          userId,
          attributes,
          mfaConfigured: true,
          rememberDevice: false,
          phoneState,
        },
      });
    }
  };

  const updateCheckBox = () => {
    setChecked(!checked);
  };

  const forgotPasswordHandler = async () => {
    const response = await forgotPassword(forgotPasswordState.email);
    if (validateEmail(forgotPasswordState.email) === false) {
      setSendEmailError(true);
      setSendEmailErrorMsg('Please enter a valid email address.');
    } else if (response.CodeDeliveryDetails) {
      updatePageToDisplay(PAGES.FORGOT_PASSWORD_SUBMIT);
    } else {
      setSendEmailError(true);
      setSendEmailErrorMsg(response.error.message);
    }
  };

  const forgotPasswordSubmitHandler = async () => {
    if (forgotPasswordState.newPassword !== forgotPasswordState.confirmNewPassword) {
      setSubmitPassError(true);
      setSubmitPassErrorMsg('Passwords do not match.');
      return;
    }

    const response = await forgotPasswordSubmit(
      forgotPasswordState.email,
      forgotPasswordState.code,
      forgotPasswordState.newPassword,
    );

    // if error
    if (response.error) {
      setSubmitPassError(true);
      if (response.error?.code === 'UserNotFoundException') {
        setSubmitPassErrorMsg('Error: Password could not be reset.');
      } else {
        setSubmitPassErrorMsg(response.error.message);
      }
      // if successful
    } else {
      setState({
        ...state,
        pageToDisplay: PAGES.LOGIN,
      });
    }
  };

  const [visible1, setVisible1] = useState(true);
  const [visible2, setVisible2] = useState(true);
  const [visible3, setVisible3] = useState(true);

  const toggleEye = (id, type) => {

    if (id === 'login-pass') {
      setVisible1(!visible1);
    } else if (id === 'new-pass') {
      setVisible2(!visible2);
    } else if (id === 'confirm-new-pass') {
      setVisible3(!visible3);
    }

    if (type === 'password') {
      document.getElementById(id).type = 'text';
    } else {
      document.getElementById(id).type = 'password';
    }
  };

  const updateUsername = (event) => {
    setLoginState({
      ...loginState,
      email: event.target.value,
    });
  };

  const updatePassword = (event) => {
    setLoginState({
      ...loginState,
      password: event.target.value,
    });
  };

  const updateCode = (event) => {
    setVerCode(event.target.value);
  };

  const updatePageToDisplay = (page) => {
    setState({
      ...state,
      pageToDisplay: page,
    });

    if (page === 'login') {
      setSignInError(false);
      setSignInErrorMsg('');
    }

    if (page === 'sms_mfa' || page === 'software_token_mfa') {
      setMfaError(false);
      setMfaErrorMsg('');
    }

    if (page === 'forgot_password') {
      setSendEmailError(false);
      setSendEmailErrorMsg('');
    }

    if (page === 'forgot_password_submit') {
      setSubmitPassError(false);
      setSubmitPassErrorMsg('');
    }
  };

  const updateUsernameFP = (event) => {
    setForgotPasswordState({
      ...forgotPasswordState,
      email: event.target.value,
    });
  };

  const updateCodeFP = (event) => {
    setForgotPasswordState({
      ...forgotPasswordState,
      code: event.target.value,
    });
  };

  const updateNewPasswordFP = (event) => {
    setForgotPasswordState({
      ...forgotPasswordState,
      newPassword: event.target.value,
    });
  };

  const updateConfirmNewPasswordFP = (event) => {
    setForgotPasswordState({
      ...forgotPasswordState,
      confirmNewPassword: event.target.value,
    });
  };

  switch (state.pageToDisplay) {
    case PAGES.FORGOT_PASSWORD:
      return ForgotPasswordComponent({
        sendEmailError,
        sendEmailErrorMsg,
        updateEmail: updateUsernameFP,
        submit: forgotPasswordHandler,
        updatePageToDisplay,
      });
    case PAGES.FORGOT_PASSWORD_SUBMIT:
      return ForgotPasswordSubmitComponent({
        visible2,
        visible3,
        toggleEye,
        forgotPasswordState,
        submitPassError,
        submitPassErrorMsg,
        updateNewPassword: updateNewPasswordFP,
        updateConfirmNewPassword: updateConfirmNewPasswordFP,
        updateCode: updateCodeFP,
        submit: forgotPasswordSubmitHandler,
        updatePageToDisplay,
      });
    case PAGES.SMS_MFA:
      return SmsComponent({
        mfaError,
        mfaErrorMsg,
        deliveryNum,
        updateCode,
        submit: handleSubmitMFA,
        updatePageToDisplay,
      });
    case PAGES.SOFTWARE_TOKEN_MFA:
      return TokenComponent({
        mfaError, mfaErrorMsg, updateCode, submit: handleSubmitMFA, updatePageToDisplay,
      });
    case PAGES.INVITATION:
      return InvitationComponent({
        invitationsArray,
        updatePageToDisplay,
        userId: signInState.userId,
        attributes: signInState.attributes,
        mfaConfigured: signInState.mfaConfigured,
        rememberDevice: signInState.rememberDevice,
        phoneState: signInState.phoneState,
        navigate,
        dispatch,
      });
    case PAGES.LOGIN:
    default:
      return LoginComponent({
        visible1,
        toggleEye,
        signInError,
        signInErrorMsg,
        updateUsername,
        loginState,
        updatePassword,
        login: handleSubmit,
        updatePageToDisplay,
        checked,
        updateCheckBox,
        navigate,
      });
  }
}

// Initial login screen
function LoginComponent(props) {
  const {
    visible1, toggleEye, signInError, signInErrorMsg,
    updateUsername, updatePassword, login, updatePageToDisplay,
    checked, updateCheckBox, loginState, navigate,
  } = props;
  return (
    <div className="outer-container">
      <div className="inner-container">
        <img src="https://cdn.shopify.com/s/files/1/0256/7900/3726/files/Logo_pink_horizontal-notagline.png?v=1654708500" alt="Swidget logo" />
        <h1 className="login">Login</h1>

        {signInError
          ? (
            <div className="error-message display-linebreak">
              <FontAwesomeIcon icon={faTriangleExclamation} />
              {signInErrorMsg}
            </div>
          )
          : null}

        <div className="input-group">
          <div className="animated-field mb-15">
            <input type="email" id="login-email" onChange={updateUsername} autoComplete="off" placeholder=" " value={loginState.email} required autoFocus />
            <label htmlFor="login-email" title="Email Address" data-title="Email Address" />
          </div>

          <div className="animated-field">
            <input
              type="password"
              id="login-pass"
              onChange={updatePassword}
              autoComplete="off"
              onKeyUp={(e) => {
                if (e.key === 'Enter') login(e);
              }}
              placeholder=" "
              required
            />
            <label htmlFor="login-pass" title="Password" data-title="Password" />
            {visible1
              ? <FontAwesomeIcon icon={faEye} onClick={() => toggleEye("login-pass", "password")} />
              : <FontAwesomeIcon icon={faEyeSlash} onClick={() => toggleEye("login-pass", "text")} />}
          </div>
        </div>

        <label className="b-contain">
          <span>Remember Me</span>
          <input type="checkbox" id="remember-me" checked={checked} value={checked} onChange={() => updateCheckBox()} />
          <div className="b-input" />
        </label>

        <div className="btn-wrapper">
          <input
            type="submit"
            value="Login"
            className="swidget-pink-btn login-btn"
            onClick={login}
          />
          <input
            type="button"
            value="Forgot Password?"
            className="link"
            onClick={() => { updatePageToDisplay(PAGES.FORGOT_PASSWORD); }}
          />
          <div>
            <span>
              Dont' have an account?
              <a className="link pink" onClick={() => navigate('/signup', { state: { hide: false } })}> Sign Up</a>
            </span>
          </div>
        </div>
        <div className="copyright-wrapper">
          <p>Copyright © 2024 Swidget Corp.</p>
          <p>
            <a href="https://www.swidget.com/pages/terms-of-use" target="_blank" className="link" rel="noreferrer">Terms of Use</a>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <a href="https://www.swidget.com/pages/privacy-policy" target="_blank" className="link" rel="noreferrer">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  );
}

// MFA SMS screen
function SmsComponent(props) {
  const {
    mfaError, mfaErrorMsg, deliveryNum, updateCode, submit, updatePageToDisplay,
  } = props;
  return (
    <div className="outer-container">
      <div className="inner-container">
        <input
          type="button"
          value="Cancel"
          className="link back-btn"
          onClick={() => { updatePageToDisplay(PAGES.LOGIN); }}
        />

        <img src="https://cdn.shopify.com/s/files/1/0256/7900/3726/files/Logo_pink_horizontal-notagline.png?v=1654708500" alt="Swidget logo" />

        <p className="mb-2">
          We texted your verification code to &nbsp;
          {deliveryNum}
        </p>

        {mfaError
          ? (
            <div className="error-message display-linebreak">
              <FontAwesomeIcon icon={faTriangleExclamation} />
              {mfaErrorMsg}
            </div>
          )
          : null}

        <div className="input-group mb-25">
          <label>Verification Code</label>
          <input
            type="number"
            placeholder="Type your verification code"
            onChange={updateCode}
            required
            autoFocus
            onKeyUp={(e) => {
              if (e.key === 'Enter') submit(e);
            }}
          />
        </div>

        <div className="btn-wrapper">
          <input
            type="submit"
            value="Submit"
            className="swidget-pink-btn centered"
            onClick={submit}
          />

          <input
            type="button"
            value="Resend Code" // to do
            className="link"
          />
        </div>
      </div>
    </div>
  );
}

// MFA token screen
function TokenComponent(props) {
  const {
    mfaError, mfaErrorMsg, updateCode, submit, updatePageToDisplay,
  } = props;
  return (
    <div className="outer-container">
      <div className="inner-container">
        <input
          type="button"
          value="Cancel"
          className="link back-btn"
          onClick={() => { updatePageToDisplay(PAGES.LOGIN); }}
        />

        <img src="https://cdn.shopify.com/s/files/1/0256/7900/3726/files/Logo_pink_horizontal-notagline.png?v=1654708500" alt="Swidget logo" />

        <h2 className="login">Two-Step Verification</h2>

        <p className="mb-2">For added security, please enter the One-Time Password (OTP) generated by your Authenticator App.</p>

        {mfaError
          ? (
            <div className="error-message display-linebreak">
              <FontAwesomeIcon icon={faTriangleExclamation} />
              {mfaErrorMsg}
            </div>
          )
          : null}

        <div className="input-group">
          <label>Enter OTP</label>
          <input
            type="number"
            placeholder="One-Time Password"
            onChange={updateCode}
            required
            autoFocus
            onKeyUp={(e) => {
              if (e.key === 'Enter') submit(e);
            }}
          />
        </div>

        <div className="btn-wrapper">
          <input
            type="submit"
            value="Submit"
            className="swidget-pink-btn centered"
            onClick={submit}
          />
        </div>
      </div>
    </div>
  );
}

// First 'forgot password' screen
function ForgotPasswordComponent(props) {
  const {
    sendEmailError, sendEmailErrorMsg, updateEmail, submit, updatePageToDisplay,
  } = props;
  return (
    <div className="outer-container">
      <div className="inner-container">
        <input
          type="button"
          value="Cancel"
          className="link back-btn"
          onClick={() => { updatePageToDisplay(PAGES.LOGIN); }}
        />

        <h2 className="login">Forgot Password</h2>
        <p className="mb-2">Enter the email address associated with your account and a verification code will be sent to it.</p>

        {sendEmailError
          ? (
            <div className="error-message display-linebreak">
              <FontAwesomeIcon icon={faTriangleExclamation} />
              {' '}
              {sendEmailErrorMsg}
            </div>
          )
          : null}

        <div className="input-group">
          <div className="animated-field mb-15">
            <input type="email" id="fp-email" onChange={updateEmail} autoComplete="off" defaultValue="" placeholder=" " required autoFocus />
            <label htmlFor="fp-email" title="Email Address" data-title="Email Address" />
          </div>
        </div>

        <input
          type="submit"
          value="Send Email"
          className="swidget-pink-btn centered"
          onClick={submit}
        />
      </div>
    </div>
  );
}

// Second 'forgot password' screen
function ForgotPasswordSubmitComponent(props) {
  const {
    visible2, visible3, toggleEye,
    forgotPasswordState, submitPassError,
    submitPassErrorMsg, updateNewPassword, updateConfirmNewPassword,
    updateCode, submit, updatePageToDisplay,
  } = props;
  return (
    <div className="outer-container">
      <div className="inner-container">
        <input
          type="button"
          value="Back"
          className="link back-btn"
          onClick={() => { updatePageToDisplay(PAGES.FORGOT_PASSWORD); }}
        />

        <img src="https://cdn.shopify.com/s/files/1/0256/7900/3726/files/Logo_pink_horizontal-notagline.png?v=1654708500" alt="Swidget logo" />

        <h2 className="login">Enter New Password</h2>

        <p>
          If your email is associated with a valid account, a unique code will be emailed to&nbsp;
          <b>{forgotPasswordState.email}</b>
          , that will allow you to reset your password.
        </p>

        <p className="mb-2">Enter the confirmation code and new password below.</p>

        {submitPassError
          ? (
            <div className="error-message display-linebreak">
              <FontAwesomeIcon icon={faTriangleExclamation} />
              {' '}
              {submitPassErrorMsg}
            </div>
          )
          : null}

        <div className="input-group">
          <div className="animated-field mb-15">
            <input type="number" id="c-code" onChange={updateCode} autoComplete="off" defaultValue="" placeholder=" " required autoFocus />
            <label htmlFor="c-code" title="Confirmation Code" data-title="Confirmation Code" />
          </div>

          <div className="animated-field mb-15">
            <input type="password" id="new-pass" onChange={updateNewPassword} autoComplete="off" defaultValue="" placeholder=" " required />
            <label htmlFor="new-pass" title="New Password" data-title="New Password" />
            {visible2
              ? <FontAwesomeIcon icon={faEye} onClick={() => toggleEye("new-pass", "password")} />
              : <FontAwesomeIcon icon={faEyeSlash} onClick={() => toggleEye("new-pass", "type")} />}
          </div>

          <div className="animated-field">
            <input type="password" id="confirm-new-pass" onChange={updateConfirmNewPassword} autoComplete="off" defaultValue="" placeholder=" " required />
            <label htmlFor="confirm-new-pass" title="Confirm New Password" data-title="Confirm New Password" />
            {visible3
              ? <FontAwesomeIcon icon={faEye} onClick={() => toggleEye("confirm-new-pass", "password")} />
              : <FontAwesomeIcon icon={faEyeSlash} onClick={() => toggleEye("confirm-new-pass", "text")} />}
          </div>
        </div>

        {/* to do */}
        <label className="b-contain">
          <span>Sign out of all devices</span>
          <input type="checkbox" />
          <div className="b-input" />
        </label>

        <input
          type="submit"
          value="Submit"
          className="swidget-pink-btn centered"
          onClick={submit}
        />
      </div>
    </div>
  );
}

function InvitationComponent(props) {
  const handlAccept = async (invitationId) => {
    const response = await respondInvitation(invitationId, 'accepted');
    console.log(response);
    props.dispatch(resetState(''));
    props.navigate('/home', {
      replace: true,
      state: {
        userId: props.userId,
        attributes: props.attributes,
        mfaConfigured: props.mfaConfigured,
        rememberDevice: props.rememberDevice,
        phoneState: props.phoneState
      },
    });
  };

  const handleReject = async (invitationId) => {
    const response = await respondInvitation(invitationId, 'declined');
    console.log(response);
    props.dispatch(resetState(''));
    props.navigate('/home', {
      replace: true,
      state: {
        userId: props.userId,
        attributes: props.attributes,
        mfaConfigured: props.mfaConfigured,
        rememberDevice: props.rememberDevice,
      },
    });
  };
  return (
    <div className="outer-container">
      <div className="inner-container">
        <img src="https://cdn.shopify.com/s/files/1/0256/7900/3726/files/Logo_pink_horizontal-notagline.png?v=1654708500" alt="Swidget logo" />
        <h2 className="login">Pending Invitation</h2>
        {props.invitationsArray.map((invitation, index) => (
          <React.Fragment key={index}>
            <p>
              <b>{`${invitation.ownedBy.firstName} ${invitation.ownedBy.lastName}`}</b>
              {' '}
              invited you to join
              {' '}
              <b>{invitation.site.name}</b>
              {' '}
              site with
              {' '}
              <b>{ capitalizeFirstLetter(invitation.role)}</b>
              {' '}
              privilegs.
            </p>
            <div className="buttons-group">
              <button className="primary-btn accept-btn  mr-05" onClick={() => handlAccept(invitation.invitationId)}>Accept</button>
              <button className="primary-btn reject-btn" onClick={() => handleReject(invitation.invitationId)}>Decline</button>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default LoginPage;
