import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Hub, PubSub } from 'aws-amplify';
import { updateAlertsIncrementally, updateAlerts } from './Update';
import ContentContainer from './ContentContainer';
import { updateAlertsOrder } from '../redux/userInfo';
import { subscribeToAlertChanges } from '../api/UserAPI';

function AlertsTab(props) {
  const dispatch = useDispatch();
  const activeList = useSelector((state) => state.userInfo.activeList);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const alertsOrder = useSelector((state) => state.userInfo.alertsOrder);
  const storeUserId = useSelector((state) => state.userInfo.userId);
  const [selectedTab, setSelectedTab] = useState('Active');
  const [unreadList, setUnreadList] = useState([]);
  const [displayedActiveList, setDisplayedActiveList] = useState(activeList);
  const [mqttConnectionRetrySignal, setMqttConnectionRetrySignal] = useState(0);
  // alert subscriptions
  const [alertSubscription, setAlertSubscription] = useState(null);
  const currentSite = useRef();
  const currentOrder = useRef();
  const scrollState = useRef();

  const alertUpdater = useCallback(async (refresh) => {
    refresh ? props.setAlertLoading(false) : props.setAlertLoading(true);
    try {
      if (refresh && !(scrollState.current)) {
        await updateAlertsIncrementally(
          dispatch,
          currentSite.current,
          null,
          25,
          [],
          [],
          currentOrder.current,
        );
      } if (!refresh) {
        await updateAlertsIncrementally(dispatch, refresh
          ? currentSite.current
          : selectedSiteId, null, 25, [], [], refresh ? currentOrder.current : alertsOrder);
      }
    } catch (err) {
      console.log(err);
    }
    props.setAlertLoading(false);
  }, [dispatch, selectedSiteId, alertsOrder, scrollState.current]);

  useEffect(() => {
    Hub.listen('pubsub', (data) => {
      const { payload } = data;
      if (
        payload.event === 'ConnectionStateChange'
      ) {
        if (payload.data.connectionState === 'Disconnected' || payload.data.connectionState === 'ConnectionDisrupted' || payload.data.connectionState === 'ConnectionDisruptedPendingNetwork') {
          setMqttConnectionRetrySignal(mqttConnectionRetrySignal + 1);
        }
      }
    });
    return () => {
      Hub.remove('pubsub');
    };
  }, [activeList]);

  // update alerts to fetch any new alerts if missed mqtt events
  useEffect(() => {
    alertUpdater(true);
  }, [mqttConnectionRetrySignal]);

  useEffect(() => {
    if (storeUserId && selectedSiteId) {
      if (alertSubscription) {
        alertSubscription.unsubscribe();
      }
      const subscription = subscribeToAlertChanges(
        storeUserId,
        selectedSiteId,
        { PubSub },
        (data) => {
          console.log('DATA INSIDE ALERT SUBSCRIPTION', data);
          alertUpdater(true);
        },
      );
      setAlertSubscription(subscription);
    }
    return () => {
      if (alertSubscription) {
        alertSubscription.unsubscribe();
      }
    };
  }, [storeUserId, selectedSiteId, mqttConnectionRetrySignal]);

  const updateScrollState = (value) => {
    scrollState.current = value;
  };

  useEffect(() => {
    async function update() {
      dispatch(updateAlertsOrder('desc'));
      await updateAlerts(dispatch, selectedSiteId);
    }
    if (selectedSiteId) update();
  }, [selectedSiteId]);

  useEffect(() => {
    if (selectedSiteId) alertUpdater(false);
  }, [alertUpdater, selectedTab]);

  useEffect(() => {
    currentSite.current = selectedSiteId;
  }, [selectedSiteId]);

  useEffect(() => {
    currentOrder.current = alertsOrder;
  }, [alertsOrder]);

  useEffect(() => {
    displayedActiveList ? setUnreadList(displayedActiveList.filter((item) => item.status === 'NEW')) : setUnreadList([]);
  }, [displayedActiveList]);

  useEffect(() => {
    setDisplayedActiveList(activeList);
  }, [activeList]);

  return (

    <>
      {selectedTab === 'Active' && <ContentContainer updateScrollState={updateScrollState} list={displayedActiveList} setSelectedEvent={props.setSelectedEvent} setAlertLoading={props.setAlertLoading} changeTab={setSelectedTab} />}
      {selectedTab === 'Unread' && <ContentContainer updateScrollState={updateScrollState} list={unreadList} setSelectedEvent={props.setSelectedEvent} setAlertLoading={props.setAlertLoading} changeTab={setSelectedTab} />}
    </>
  );
}
export default AlertsTab;
