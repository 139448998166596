/* eslint-disable no-param-reassign */

import * as AWS from 'aws-sdk';
import { getKinesisVideoCreds } from '../api/UserAPI';

export async function getStreamSessionUrl(thingName) {
  const streamName = thingName;
  const [, siteId, deviceId] = streamName.split('_');
  const creds = await getKinesisVideoCreds(siteId, deviceId);
  try {
    if (!thingName) {
      console.log('thing name is not defined:', thingName);
      return;
    }
    const kinesisVideoArchivedContent = new AWS.KinesisVideoArchivedMedia(
      {
        httpOptions: {
          timeout: 5000,
        },
        maxRetries: 3,
        credentials: {
          accessKeyId: creds.credentials.accessKeyId,
          secretAccessKey: creds.credentials.secretAccessKey,
          sessionToken: creds.credentials.sessionToken,
        },
        region: AWS.config.region,
      },
    );
    const kinesisVideo = new AWS.KinesisVideo(
      {
        credentials: {
          accessKeyId: creds.credentials.accessKeyId,
          secretAccessKey: creds.credentials.secretAccessKey,
          sessionToken: creds.credentials.sessionToken,
        },
        region: AWS.config.region,
      },
    );
    const dataEndpointParams = {
      APIName: 'GET_HLS_STREAMING_SESSION_URL',
      StreamName: streamName,
    };
    console.log('endpoint params', dataEndpointParams);
    const endpoint = await kinesisVideo
      .getDataEndpoint(dataEndpointParams)
      .promise();
    kinesisVideoArchivedContent.endpoint = new AWS.Endpoint(endpoint.DataEndpoint);
    const sessionUrlParams = {
      StreamName: streamName,
      PlaybackMode: 'LIVE',
      Expires: 5000, // seconds
      ContainerFormat: 'FRAGMENTED_MP4', // MPEG_TS
      DiscontinuityMode: 'ALWAYS',
      DisplayFragmentTimestamp: 'ALWAYS',
      MaxMediaPlaylistFragmentResults: 200,
    };
    console.log('session url params', sessionUrlParams);
    const streamSession = await kinesisVideoArchivedContent
      .getHLSStreamingSessionURL(sessionUrlParams)
      .promise();
    return streamSession.HLSStreamingSessionURL;
  } catch (error) {
    console.log('ERROR', error);
    return null;
  }
}

export async function getIceServers(
  ChannelARN,
  endpointsByProtocol,
  deviceId,
  credentials,
  siteId,
) {
  let creds;
  if (credentials) creds = credentials;
  else creds = await getKinesisVideoCreds(siteId, deviceId);
  const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
    endpoint: endpointsByProtocol.HTTPS,
    correctClockSkew: true,
    credentials: {
      accessKeyId: credentials ? creds.accessKeyId : creds.credentials.accessKeyId,
      secretAccessKey: credentials ? creds.secretAccessKey : creds.credentials.secretAccessKey,
      sessionToken: credentials ? creds.sessionToken : creds.credentials.sessionToken,
    },
    region: AWS.config.region ?? 'us-east-1',
  });
  const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
    .getIceServerConfig({
      ChannelARN,
    })
    .promise();
  const iceServers = [
    { urls: 'stun:stun.kinesisvideo.us-east-1.amazonaws.com:443' },
  ];
  getIceServerConfigResponse.IceServerList.forEach((iceServer) => iceServers.push({
    urls: iceServer.Uris,
    username: iceServer.Username,
    credential: iceServer.Password,
  }));
  return iceServers;
}

// export async function getIceServers(ChannelARN, endpointsByProtocol) {
//   return [
//     { urls: `stun:stun.kinesisvideo.us-east-1.amazonaws.com:443` },
//   ];
// }

export async function getEndpointsByProtocol(ChannelARN, kinesisVideo, role) {
  const getSignalingChannelEndpointResponse = await kinesisVideo
    .getSignalingChannelEndpoint({
      ChannelARN,
      SingleMasterChannelEndpointConfiguration: {
        Protocols: ['WSS', 'HTTPS'],
        Role: role,
      },
    })
    .promise();

  const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
    (endpoints, endpoint) => {
      endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
      return endpoints;
    },
    {},
  );
  return endpointsByProtocol;
}

export async function getChannelARN(thingName, kinesisVideo) {
  const endpoint = await kinesisVideo
    .describeSignalingChannel({
      ChannelName: thingName,
    })
    .promise();
  const {
    ChannelInfo: { ChannelARN },
  } = endpoint;
  return ChannelARN;
}

export async function getKinesisVideoImage(
  streamName,
  eventStartTime,
  eventEndTime,
  kinesisVideoArchivedMedia,
  maxResults,
  samplingIntervalInMillis,
) {
  const params = {
    StreamName: streamName,
    MaxResults: maxResults,
    ImageSelectorType: 'PRODUCER_TIMESTAMP',
    StartTimestamp: eventStartTime,
    EndTimestamp: eventEndTime,
    Format: 'JPEG',
    SamplingInterval: samplingIntervalInMillis,
    HeightPixels: 91,
    WidthPixels: 158,
  };
  const images = await (kinesisVideoArchivedMedia.getImages(params).promise());
  if (!images || !images?.Images) return;
  const found = images.Images.find((image) => image.ImageContent);
  if (!found) return null;
  return found.ImageContent;
}
