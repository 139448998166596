import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { generateKinesisCredential, getPropertyInfo, endLinkSession } from '../api/UserAPI';
import LiveVideoStream from './LiveVideoStream';
import swidgetLogo from '../assets/swidget-logo.png';
import { formatDate, formatTime } from './FormatStuff';

function PublicLiveView() {
  const [propInfo, setPropInfo] = useState(null);
  const [link, setLink] = useState({});
  const [creds, setCreds] = useState(null);
  const [videoClassName, setVideoClassName] = useState('video-wrapper');
  const [cameraList, setCameraList] = useState([]);
  const [error, setError] = useState(false);
  const { pathname } = useLocation();
  const linkId = pathname.replace(/^.*links\//, '');

  const getInfo = async (linkId) => {
    const devicesIds = [];
    const cameras = [];
    const response = await getPropertyInfo(linkId);
    if (response?.link) {
      const { devices } = response.room;
      const { link } = response;
      setLink(link);
      devices.forEach((device) => {
        if (device.insert.type.toLowerCase() === 'video') {
          devicesIds.push(device.deviceId);
          cameras.push(device);
        }
      });
      setCameraList(cameras);
      const creds = await generateKinesisCredential(linkId, devicesIds);
      setCreds(creds);
      setPropInfo(response);
    } else setError(true);
  };

  useEffect(() => {
    getInfo(linkId);
  }, [linkId]);

  window.matchMedia('(orientation: portrait)').addEventListener('change', (e) => {
    const portrait = e.matches;
    if (portrait) {
      setVideoClassName('video-wrapper');
    } else {
      setVideoClassName('landscape-mode');
    }
  });

  useEffect(() => {
    const interval = setInterval(async () => {
      getInfo(linkId);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const getCameraComponent = (cameraList) => (
    cameraList?.length && creds
      ? (
        <div className={videoClassName}>
          {cameraList?.map((camera, index) => (
            <div key={camera.thingName}>
              <LiveVideoStream
                key={camera.thingName}
                thingName={camera.thingName}
                device={camera}
                creds={creds.credentials[index]}
              />
            </div>
          ))}
        </div>
      )
      : null
  );

  const endSession = async () => {
    await endLinkSession(linkId);
    getInfo(linkId);
  };

  return (
    <div className="public-page">
      <img src={swidgetLogo} alt="Swidget logo" className="logo" />
      <div className="public-page-continaner">
        { error
          ? <h2 className="black"> Sorry, the link has expired!</h2>
          : (
            <>
              <h2 className="black">{propInfo?.room?.name}</h2>
              <div className="space-between time-btn-container">
                <h4 className="black">
                  <b>Expires At: </b>
                  {`${link.expiresAt ? `${formatTime(link.expiresAt)} ${formatDate(link.expiresAt)}` : ''}`}
                </h4>
                <button className="primary-btn" onClick={endSession}>End Session</button>
              </div>
              {getCameraComponent(cameraList)}
            </>
          )}
      </div>
    </div>
  );
}

export default PublicLiveView;
