/* eslint-disable */
import { API, PubSub, Auth } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import { AwsClient } from 'aws4fetch';
import { getKinesisVideoImage } from '../services/kinesis';
import { isValidUserId } from '../utils/utils';

export async function getSites() {
  try {
    const response = await API.get('swidget-api', 'sites');
    return response;
  } catch (error) {
    return { error };
  }
}

export async function getSite(siteId) {
  try {
    const response = await API.get('swidget-api', `sites/${siteId}`);
    return response;
  } catch (error) {
    return { error };
  }
}

export async function getAutomations(siteId) {
  try {
    const response = await API.get('swidget-api', `automations/${siteId}`);
    return response;
  } catch (error) {
    return { error };
  }
}

export async function patchAutomation(siteId, automationId, toggleValue) {
  try {
    return await API.patch('swidget-api', `automations/${siteId}/${automationId}`, {
      body: {
        enabled: toggleValue,
      },
    });
  } catch (error) {
    return { error };
  }
}

export async function getRooms(siteId) {
  try {
    const response = await API.get('swidget-api', `sites/${siteId}/rooms`);
    return response;
  } catch (error) {
    return { error };
  }
}

export async function getRoomInfo(siteId, roomId) {
  try {
    const response = await API.get('swidget-api', `sites/${siteId}/rooms/${roomId}`);
    return response;
  } catch (error) {
    return { error };
  }
}

export async function getDevices(siteId) {
  try {
    const response = await API.get('swidget-api', `sites/${siteId}/devices`);
    return response;
  } catch (error) {
    return { error };
  }
}

export async function updateSchedule(siteId, hoursObject, exceptionList, cooldownTime) {
  try {
    const response = await API.patch('swidget-api', `sites/${siteId}`, {
      body: {
        schedule: {
          hoursOfOperation: hoursObject,
          exceptions: exceptionList,
        },
        notification: {
          cooldown: {
            duration: cooldownTime,
          }
        }
      },
    });
    return response;
  } catch (error) {
    return { error };
  }
}

export function subscribeToDeviceChanges(userId, siteId, deviceId, onStateChange, isConnected) {
  try {
    if(!isValidUserId(userId) || !isConnected) {
      return;
    }
    const TOPIC_IN = `devices/${userId}/${siteId}/${deviceId}/datastream`;
    const subscription = PubSub.subscribe(TOPIC_IN).subscribe({
      next: (state) => {
        onStateChange({ data: state.value });
      },
      complete: () => {
        console.log('Done');
      },
    });
    return subscription;
  } catch (error) {
    console.log(error);
  }
}
export function subscribeToAlertChanges(userId, siteId, { PubSub }, onAlert) {
  if(!isValidUserId(userId)) {
    return;
  }
  const TOPIC_IN = `devices/${userId}/${siteId}/updates/alerts-added`;
  const subscribe = (PubSub, TOPIC_IN) => {
    PubSub.subscribe(TOPIC_IN).subscribe({
      next: (state) => {
        onAlert(state.value.payload);
      },
      error: error => {
        console.log("Alert subscription error", error);
      },
      complete: () => {
        console.log('Done');
      },
    });
  }
  const subscription = subscribe(PubSub, TOPIC_IN);
  return subscription;
}

export async function requestDatastream(userId, siteId, deviceId, isConnected) {
  try {
    if(!isValidUserId(userId) || !isConnected) {
      return;
    }
  const TOPIC_OUT = `devices/${userId}/${siteId}/${deviceId}/m/cmd`;
  await PubSub.publish(TOPIC_OUT, { datastream: true });
  } catch (error) {
    console.log("Error requesting datastream", error);
  }
}

export async function requestDatapoint(userId, siteId, deviceId, isConnected) {
  try {
    if(!isValidUserId(userId) || !isConnected) {
      return;
    }
  const TOPIC_OUT = `devices/${userId}/${siteId}/${deviceId}/m/cmd`;
  await PubSub.publish(TOPIC_OUT, { datapoint: true });
  } catch (error) {
      console.log(error)
  }
}

export async function getDeviceState(userId, siteId, deviceId, isConnected) {  
  try {    
    if(!isValidUserId(userId) || !isConnected) {
      return;    
    }    
    const TOPIC_OUT = `devices/${userId}/${siteId}/${deviceId}/m/cmd`;    
    const TOPIC_IN = `devices/${userId}/${siteId}/${deviceId}/resp`;    
    let subscription;    
    const request = await new Promise((resolve, reject) => {      
      subscription = PubSub.subscribe(TOPIC_IN).subscribe({        
        next: (state) => {         
           resolve(state);        
          },        
          error: (error) => {          
            console.error(error);          
            resolve(null);        
          },        
          complete: () => {          
            console.log('Done');       
          },      
        });      
        PubSub.publish(TOPIC_OUT, { datapoint: true }).then(() => {        
          const timeout = setTimeout(() => {        
            clearTimeout(timeout);          
            resolve(null);        
          }, 3000); 

        })    
      });    
      subscription?.unsubscribe();
        return request;
    } catch (error) {
    console.log('error in getting device state', error)
  }
}

export async function getDeviceGroups(siteId, hasRoomValue) {
  try {
    const response = await API.get('swidget-api', `sites/${siteId}/deviceGroups`, {
      queryStringParameters: {
        hasRoom: hasRoomValue,
      },
    });
    return response;
  } catch (error) {
    return { error };
  }
}

export async function patchDeviceGroups(siteId, deviceGroupId, roomId) {
  try {
    const response = await API.patch('swidget-api', `sites/${siteId}/deviceGroups/${deviceGroupId}`, {
      body: {
        roomId,
      },
    });
    return response;
  } catch (error) {
    return { error };
  }
}

export async function makeGetEventsHttpRequest(siteId, { queryStringParameters }) {
  const creds = await Auth.Credentials.get();
  const aws = new AwsClient({
    region: process.env.REACT_APP_REGION,
    service: 'execute-api',
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken,
  });
  const buildQueryStringParams = (queryStringParameters) => {
    const keys = Object.keys(queryStringParameters);
    const notNullKeys = keys.filter(
      (key) => queryStringParameters[key] !== null
      && (Array.isArray(queryStringParameters[key]) ? queryStringParameters[key].length : true),
    );
    if (!notNullKeys.length) return '';
    let res = '?';
    notNullKeys.forEach((key, index) => {
      if (index > 0) res += '&';
      if (Array.isArray(queryStringParameters[key])) {
        queryStringParameters[key].forEach((value, index) => {
          res += `${key}=${value}`;
          if (index < queryStringParameters[key].length - 1) res += '&';
        });
      } else {
        res += `${key}=${queryStringParameters[key]}`;
      }
    });
    return res;
  };
  const res = await aws.fetch(`${process.env.REACT_APP_API_BASE_URL}/sites/${siteId}/events${buildQueryStringParams(queryStringParameters)}`, {
    method: 'GET',
  });
  return res.json();
}

export async function getKinesisVideoCreds(siteId, deviceId) {
  let creds = JSON.parse(localStorage.getItem(`${deviceId}`));
  if (!creds ||  Date.parse(creds?.credentials?.expiration) <= Date.now() - 60000) {
  try {
    const response = await API.get('swidget-api', `sites/${siteId}/devices/${deviceId}/kinesisVideo/credentials`);
    localStorage.setItem(`${deviceId}`, JSON.stringify(response));
    return response;
  } catch (error) {
    return { error };
  }
} else return creds;
}

export async function getEvents({
  siteId, roomId, limit, lastTimestamp, startTimestamp, endTimestamp, source, type, orderBy, status,
} = {}) {
  try {
    const queryStringParameters = {};
    if (limit) queryStringParameters.limit = limit;
    // else queryStringParameters.limit = 25;
    if (roomId) queryStringParameters.roomId = roomId;
    if (type) queryStringParameters.type = type;
    if (orderBy) queryStringParameters.orderBy = orderBy;
    if (lastTimestamp) queryStringParameters.lastTimestamp = lastTimestamp;
    if (startTimestamp) queryStringParameters.startTimestamp = startTimestamp;
    if (endTimestamp) queryStringParameters.endTimestamp = endTimestamp;
    if (status) queryStringParameters.status = status;
    if (source) queryStringParameters.source = source;
    const response = await makeGetEventsHttpRequest(siteId, { queryStringParameters });
    if (!response.events || (type === 'alert' && !limit)) return response;
    const kinesisLibForStreams = {};
    const streamNames = response.events.filter((event) => event.source === 'video' && event.content?.streamName).map((event) => event.content.streamName);
    const uniqueStreamNames = [...new Set(streamNames)];
  
    await Promise.all(uniqueStreamNames.map(async (streamName) => {
      try {
        const [, siteId, deviceId]= streamName.split('_');
        const creds = await getKinesisVideoCreds(siteId, deviceId);
        const endpoint = await getEndpoint('GET_IMAGES', streamName);
        const kinesisVideo = new AWS.KinesisVideoArchivedMedia({
          httpOptions: {
            timeout: 10000,
          },
          maxRetries: 3,
          credentials: {
            accessKeyId: creds.credentials.accessKeyId,
            secretAccessKey: creds.credentials.secretAccessKey,
            sessionToken: creds.credentials.sessionToken,
          },
          region: AWS.config.region,
        });
        kinesisVideo.endpoint = new AWS.Endpoint(endpoint.DataEndpoint);
        kinesisLibForStreams[streamName] = kinesisVideo;
      } catch (error) {
        console.log(error);
      }
    }));
    const greyImage = 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAJ4AAABbCAQAAAAoqfT3AAAAiUlEQVR42u3QMQEAAAgDINe/pE20g5cHRCA9xVHkyZMnTx7y5MmThzx58uTJQ548efKQJ0+ePOTJkydPHvLkyZOHPHny5CFPnjx58pAnT5485MmTJ08e8uTJk4c8efLkIU+ePHnykCdPnjzkyZMnD3ny5MmThzx58uQhT548efKQJ0+ePOTJe2kBmw2qoV596moAAAAASUVORK5CYII=';
    response.events = await Promise.all(response.events.map(async (event) => {
      try {
        if (event.source !== 'video') return event;
        if (!event.content?.streamName) {
          if (!event.content?.imageUrl) {
            event.content.imageUrl = greyImage;
          }
          return event;
        }
        if (event.content?.imageUrl) {
          return event
        }
        const samplingIntervalInMillis = 300;
        const maxResults = Math.min(100, Math.round(event.content.duration));
        const startTime = new Date(event.content.start * 1000);
        const maxDuration = Math.min(299, event.content.duration);
        const endTime = new Date((event.content.start + maxDuration) * 1000);
        const kinesisVideo = kinesisLibForStreams[event.content.streamName];
        const image = (await getKinesisVideoImage(
          event.content?.streamName,
          startTime,
          endTime,
          kinesisVideo,
          maxResults,
          samplingIntervalInMillis,
        )
        );
        if (image) {
          event.content.imageUrl = `data:image/png;base64, ${image}`;
        } else {
          event.content.imageUrl = greyImage;
        }
        return event;
      } catch (error) {
        event.content.imageUrl = greyImage;
        return event;
      }
    }));
    return response;
  } catch (error) {
    return { error };
  }
}

export async function getEvent(siteId, eventId) {
  try {
    const response = await API.get('swidget-api', `/sites/${siteId}/events/${eventId}`);
    return response;
  } catch (error) {
    return { error };
  }
}

export async function patchEvent(siteId, eventId, statusValue) {
  try {
    return await API.patch('swidget-api', `sites/${siteId}/events/${eventId}`, {
      body: {
        status: statusValue,
      },
    });
  } catch (error) {
    return { error };
  }
}

export async function getEventsFile(timestamp, siteId, roomId) {
  try {
    return await API.get('swidget-api', `sites/${siteId}/events`, {
      queryStringParameters: {
        sinceTimestamp: timestamp,
        format: 'csv',
        siteId,
        roomId,
      },
    });
  } catch (error) {
    return { error };
  }
}

export async function createRoom(siteId, roomName) {
  try {
    return await API.post('swidget-api', `sites/${siteId}/rooms`, {
      body: {
        name: roomName,
      },
    });
  } catch (error) {
    return { error };
  }
}

export async function postEventComment(siteId, eventId, message) {
  try {
    return await API.post('swidget-api', `sites/${siteId}/events/${eventId}/comments`, {
      body: {
        comment: message,
      },
    });
  } catch (error) {
    return { error };
  }
}

export async function postPropertyComment(siteId, roomId, message) {
  try {
    return await API.post('swidget-api', `sites/${siteId}/rooms/${roomId}/comments`, {
      body: {
        comment: message,
      },
    });
  } catch (error) {
    return { error };
  }
}

export async function getVideoEvents() {
  try {
    return await API.get('swidget-api', 'events', {
      queryStringParameters: {
        type: 'video',
      },
    });
  } catch (error) {
    return { error };
  }
}

export async function updateVideoSettings(siteId, deviceId, settings) {
  try {
    return await API.patch('swidget-api', `sites/${siteId}/devices/${deviceId}/config`, {
      body: {
        insert: {
          video: settings,
        },
      },
    });
  } catch (error) {
    return { error };
  }
}

export async function getEndpoint(name, streamName) {
  const [, siteId, deviceId]= streamName.split('_');
  const creds = await getKinesisVideoCreds(siteId, deviceId);
  const kv = new AWS.KinesisVideo({
    credentials: {
      accessKeyId: creds.credentials.accessKeyId,
      secretAccessKey: creds.credentials.secretAccessKey,
      sessionToken: creds.credentials.sessionToken,
    },
    region: AWS.config.region,
  });
  return kv.getDataEndpoint({
    APIName: name,
    StreamName: streamName,
  }).promise();
}

// list all videos available in the given time range
export async function listFragments(startTime, endTime, streamName) {
  const [, siteId, deviceId]= streamName.split('_');
  const creds = await getKinesisVideoCreds(siteId, deviceId);
  const endpoint = await getEndpoint('LIST_FRAGMENTS', streamName);

  const kvs = new AWS.KinesisVideoArchivedMedia({
    httpOptions: {
      timeout: 500,
    },
    maxRetries: 3,
    credentials: {
      accessKeyId: creds.credentials.accessKeyId,
      secretAccessKey: creds.credentials.secretAccessKey,
      sessionToken: creds.credentials.sessionToken,
    },
    region: AWS.config.region,
  });

  kvs.endpoint = new AWS.Endpoint(endpoint.DataEndpoint);
  const params = {
    StreamName: streamName,
    FragmentSelector: {
      TimestampRange: {
        StartTimestamp: startTime,
        EndTimestamp: endTime,
      },
      FragmentSelectorType: 'PRODUCER_TIMESTAMP',
    },
  };
  return kvs.listFragments(params).promise();
}

export async function downloadMP4(startTime, endTime, streamName) {
  const endpoint = await getEndpoint('GET_CLIP', streamName);
  const [, siteId, deviceId]= streamName.split('_');
  const creds = await getKinesisVideoCreds(siteId, deviceId);
  const kvs = new AWS.KinesisVideoArchivedMedia({
    httpOptions: {
      timeout: 5000,
    },
    maxRetries: 3,
    credentials: {
      accessKeyId: creds.credentials.accessKeyId,
      secretAccessKey: creds.credentials.secretAccessKey,
      sessionToken: creds.credentials.sessionToken,
    },
    region: AWS.config.region,
  });

  kvs.endpoint = new AWS.Endpoint(endpoint.DataEndpoint);
  return kvs.getClip({
    StreamName: streamName,
    ClipFragmentSelector: {
      FragmentSelectorType: 'PRODUCER_TIMESTAMP',
      TimestampRange: {
        StartTimestamp: startTime,
        EndTimestamp: endTime,
      },
    },
  }).promise();
}

export async function getHLSStreamSessionUrl(startTime, endTime, streamName) {
  const endpoint = await getEndpoint('GET_HLS_STREAMING_SESSION_URL', streamName);
  const [, siteId, deviceId]= streamName.split('_');
  const creds = await getKinesisVideoCreds(siteId, deviceId);

  const kvs = new AWS.KinesisVideoArchivedMedia({
    httpOptions: {
      timeout: 5000,
    },
    maxRetries: 3,
    credentials: {
      accessKeyId: creds.credentials.accessKeyId,
      secretAccessKey: creds.credentials.secretAccessKey,
      sessionToken: creds.credentials.sessionToken,
    },
    region: AWS.config.region,
  });

  kvs.endpoint = new AWS.Endpoint(endpoint.DataEndpoint);
  return kvs.getHLSStreamingSessionURL({
    StreamName: streamName,
    HLSFragmentSelector: {
      FragmentSelectorType: 'PRODUCER_TIMESTAMP',
      TimestampRange: {
        StartTimestamp: startTime,
        EndTimestamp: endTime,
      },
    },
    PlaybackMode: 'ON_DEMAND',
    DiscontinuityMode: 'ON_DISCONTINUITY',
  }).promise();
}

export async function updateRoomName(siteId, roomId, newName) {
  try {
    return await API.put('swidget-api', `sites/${siteId}/rooms/${roomId}`, {
      body: {
        name: newName,
      },
    });
  } catch (error) {
    return { error };
  }
}

export async function submitFeedbackForm(subject, description, type) {
  try {
    return await API.post('swidget-api', 'clients/zendesk/requests', {
      body: {
        subject,
        description,
        type, // bug/feature/enhancement
      },
    });
  } catch (error) {
    return { error };
  }
}

export async function getAudience() {
  try {
    const response = await API.post('swidget-api', 'users/checkExistence');
    return response;
  } catch (error) {
    return { error };
  }
}

export async function getInvitations(siteId) {
  try {
    const response = await API.get('swidget-api', `sites/${siteId}/invitations`);
    return response;
  } catch (error) {
    return { error };
  }
}

export async function addInvitee(siteId, email, accessLevel) {
  try {
    const response = await API.post('swidget-api', `sites/${siteId}/invitations`, {
      body: {
        email,
        role: accessLevel,
      },
    });
    return response;
  } catch (error) {
    return { error };
  }
}

export async function deleteInvitee(siteId, invitationId) {
  try {
    const response = await API.del('swidget-api', `sites/${siteId}/invitations/${invitationId}`);
    return response;
  } catch (error) {
    return { error };
  }
}

export async function updateInvitee(siteId, invitationId, accessLevel) {
  try {
    const response = await API.patch('swidget-api', `sites/${siteId}/invitations/${invitationId}`, {
      body: {
        role: accessLevel,
      },
    });
    return response;
  } catch (error) {
    return { error };
  }
}

export async function checkInvitation() {
  try {
    const response = await API.get('swidget-api', 'invitations');
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function respondInvitation(invitationId, status) {
  try {
    const response = await API.patch('swidget-api', `invitations/${invitationId}`, {
      body: {
        status,
      },
    });
    return response;
  } catch (error) {
    return { error };
  }
}

export async function updatePhoneNumber(phoneNumber) {
  try {
    const response = await API.post('swidget-api', 'users/current/phoneNumber', {
      body: {
        phoneNumber,
      },
    });
    return response;
  } catch (error) {
    return { error };
  }
}

export async function verifyPhoneNumber(code) {
  try {
    const response = await API.post('swidget-api', 'users/current/phoneNumber/verify', {
      body: {
        code,
      },
    });
    return response;
  } catch (error) {
    return { error };
  }
}

export async function deletePhoneNumber() {
  try {
    const response = await API.del('swidget-api', 'users/current/phoneNumber');
    return response;
  } catch (error) {
    return { error };
  }
}

export async function updateNotification(siteId, enableEmailStatus, enableSmsStatus, enablePushStatus) {
  try {
    const response = await API.patch('swidget-api', `sites/${siteId}`, {
      body: {
        notification: {
          email: {
            enableAlerts: enableEmailStatus
          },
          sms: {
            enableAlerts: enableSmsStatus
          },
          push: {
            isEnabled: enablePushStatus
          }
        }
      }
    });
    return response;
  } catch (error) {
    return { error }
  }
}

export async function patchRoomSettings (siteId, roomId, alertsMode) {
  try {
    const response = await API.patch('swidget-api', `sites/${siteId}/rooms/${roomId}`, {
      body: {
        settings: {
          alerts: {
            isSilent: alertsMode
          }
        }
      }
    });
    return response;
  } catch (error) {
    return {error}
  }
}

export async function deleteDeviceGroup (siteId, deviceGroupId) {
  try {
    const response = await API.del('swidget-api', `sites/${siteId}/deviceGroups/${deviceGroupId}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function toggleSensor (siteId, deviceId, value) {
  try {
    const response = await API.patch('swidget-api', `sites/${siteId}/devices/${deviceId}`, {
      body: {
        tags: [{
          key: "installationStatus",
          value,
        }]
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateThreatLevel (siteId, eventId, level) {
  try {
    const response = await API.patch('swidget-api', `sites/${siteId}/events/${eventId}`, {
      body: {
        threatLevel: level,
      }
    });
    return response;
  } catch (error) {
    return error
  }
}

export async function archiveSelectedProperty (siteId, roomId) {
  try {
    const response = await API.post('swidget-api', `sites/${siteId}/rooms/${roomId}/archive`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function createSharedLink (siteId, roomId, expiresAt, linkName) {
  try {
    const response = await API.post('swidget-api', `sites/${siteId}/rooms/${roomId}/links`, {
      body: {
        name: linkName,
        expiresAt
      }
    })
    return response;
  } catch (error) {
    return error;
  }
}

export async function getPropertyInfo (linkId) {
  try {
    const response = await API.get('swidget-api', `links/${linkId}/info`);
    return response;
  } catch (error) {
    console.log('get property info error', error)
  }
};

export async function generateKinesisCredential (linkId, deviceIds) {
  try {
    const response = await API.post('swidget-api', `links/${linkId}/kinesisCredentials`, {
      body: {
        deviceIds
      }
    })
    return response;
  } catch (error) {
    console.log('Generate Kinesis Credential error: ', error)
  }
};

export async function listSharedLinks (siteId, roomId) {
  try {
    const response = await API.get('swidget-api', `sites/${siteId}/rooms/${roomId}/links`);
    return response;
  } catch (error) {
    console.log('list shared links error', error);
  }
};

export async function deleteSharedLink (siteId, roomId, linkId) {
  try {
    const response = await API.del('swidget-api', `sites/${siteId}/rooms/${roomId}/links/${linkId}`);
    return response;
  } catch (error) {
    console.log('delete shared link error', error);
  }
}

export async function extendSharedLinkTime (siteId, roomId, linkId, expiresInTime, linkName ) {
  try {
    const response = await API.patch('swidget-api', `sites/${siteId}/rooms/${roomId}/links/${linkId}`, {
      body: {
        expiresAt: expiresInTime,
        name: linkName,
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function endLinkSession (linkId) {
  try {
    const response = await API.del('swidget-api', `links/${linkId}`);
    return response;
  } catch (error) {
    console.log(error);
  }
}
