import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLink, modifySharedLink } from './Update';

function SetTimeComponent(props) {
  const dispatch = useDispatch();
  const [timerValue, setTimerValue] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [error, setError] = useState(false);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const selectedRoomId = useSelector((state) => state.userInfo.selectedRoomId);
  const [allowedTime, setAllowedTime] = useState(8);
  const [linkName, setLinkName] = useState('');

  const handleName = (event) => {
    setLinkName(event.target.value);
  };

  useEffect(() => {
    if (props.link) {
      setAllowedTime(
        Math.floor((props.link.maxExpiryTimestamp - props.link.expiresAt) / 3600000),
      );
    }
  }, [props.link]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    const time = Number(value);
    if (id === 'hours') {
      if (time <= 8) {
        setTimerValue({ ...timerValue, [id]: time });
        setError(false);
      } else setError(true);
    }
    if (id === 'minutes' || id === 'seconds') {
      if (timerValue.hours > 7) {
        if (time === 0) {
          setTimerValue({ ...timerValue, [id]: time });
          setError(false);
        } else setError(true);
      } else if (time <= 59) {
        setTimerValue({ ...timerValue, [id]: time });
        setError(false);
      } else setError(true);
    }
  };

  const createSharedLink = async () => {
    const expiresInTime = (
      timerValue.hours * 3600
      + timerValue.minutes * 60
      + timerValue.seconds)
      * 1000;
    if (expiresInTime < 28800000) { // 8 hours
      const expiresAt = Date.now() + expiresInTime;
      const link = await createLink(
        dispatch,
        selectedSiteId,
        selectedRoomId,
        expiresAt,
        linkName,
      );
      if (link) {
        props.setLink(link);
        props.setShowLink(true);
      }
    } else {
      setError(true);
    }
  };

  const extendLinkTime = async () => {
    const expiresInTime = (
      timerValue.hours * 3600
      + timerValue.minutes * 60
      + timerValue.seconds)
      * 1000;

    const newExpiryTime = props.link.expiresAt + expiresInTime;

    if (newExpiryTime < props.link.maxExpiryTimestamp) {
      const modifiedLink = await modifySharedLink(
        dispatch,
        selectedSiteId,
        selectedRoomId,
        props.link.linkId,
        newExpiryTime,
        linkName === '' ? props.link.name : linkName,
      );
      if (modifiedLink) {
        props.close(false);
      }
    } else {
      setError(true);
    }
  };

  return (
    <div className="time-component-container">
      <label className="mb-05">
        <b>Share expires in&nbsp;</b>
      </label>
      <div className="timer">
        <div className="timer-input">
          <label>Hours</label>
          <input type="number" min="1" max="8" id="hours" onChange={handleChange} />
        </div>
        <div className="timer-input">
          <label>Minutes</label>
          <input type="number" min="0" max="59" id="minutes" onChange={handleChange} />
        </div>
        <div className="timer-input">
          <label>Seconds</label>
          <input type="number" min="0" max="59" id="seconds" onChange={handleChange} />
        </div>
        {props.create
          ? <button className="primary-btn" onClick={createSharedLink}>Create Link</button>
          : <button className="primary-btn" onClick={extendLinkTime}>Extend Time</button>}
      </div>
      {error && <h6 className="error">Invalid value</h6>}
      <h5 className="black italic mt-05 mb-1">{`maximum time allowed ${allowedTime} hours`}</h5>
      <div className="timer-input">
        <label>{`${props.create ? 'Name' : 'New Name (Optional)'}`}</label>
        <input type="text" id="link-name" onChange={handleName} />
      </div>
    </div>
  );
}
export default SetTimeComponent;
