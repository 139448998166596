import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Backdrop } from '@mui/material';
import edit from '../assets/edit.svg';
import save from '../assets/save.svg';
import StatusBar from './StatusBar';
import ArchivePopup from './ArchivePopup';
import ViewEventPopup from './ViewEventPopup';
import { getRooms, updateRoomName, patchDeviceGroups } from '../api/UserAPI';
import {
  updateEditRoom, updateRoomList, updateSelectedRoomName, updateSelectedKitId,
} from '../redux/userInfo';
import { updateUnassignedKits } from './Update';
import AlertsToggle from './AlertsToggle';

function TabsHeader(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({ archivePopup: false, assignKitPopup: false });
  const selectedRoomName = useSelector((state) => state.userInfo.selectedRoomName);
  const selectedRoomId = useSelector((state) => state.userInfo.selectedRoomId);
  const roomList = useSelector((state) => state.userInfo.roomList);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const deviceList = useSelector((state) => state.userInfo.deviceList);
  const viewEventPopup = useSelector((state) => state.userInfo.viewEventPopup);
  const editRoom = useSelector((state) => state.userInfo.editRoom);
  const archivedList = useSelector((state) => state.userInfo.archivedList);
  const selectedKitId = useSelector((state) => state.userInfo.selectedKitId);
  // list of unassigned kit objects
  const unassignedKits = useSelector((state) => state.userInfo.unassignedKits);
  const [emptyKitError, setEmptyKitError] = useState(false);
  const [roomError, setRoomError] = useState(false);
  const [chosenKit, setChosenKit] = useState();
  const [slectedKitIndex, setSelectedKitIndex] = useState();
  const [roomNewName, setRoomNewName] = useState(selectedRoomName);
  const [updatingName, setUpdatingName] = useState(false);

  useEffect(() => {
    setRoomNewName(selectedRoomName);
  }, [selectedRoomName]);
  // show/hide text box to edit room name
  const editRoomName = () => {
    dispatch(updateEditRoom(!editRoom));
  };

  const InputHandleChange = (e) => {
    setRoomNewName(e.target.value);
  };
  // to do: set room name length limit
  const saveRoomName = async () => {
    if (roomList.rooms && roomList.rooms.length) {
      const nameList = roomList.rooms.map(({ name }) => (name));

      // if room name already exists - show error
      if (nameList?.includes(roomNewName)) {
        setRoomError(true);

        // else - update name & refresh
      } else {
        setRoomError(false);
        dispatch(updateEditRoom(false));
        setUpdatingName(true);
        const response1 = await updateRoomName(selectedSiteId, selectedRoomId, roomNewName);
        if (response1.success) {
          const response2 = await getRooms(selectedSiteId);
          dispatch(updateRoomList(response2));
          dispatch(updateSelectedRoomName(roomNewName));
          setUpdatingName(false);
        }
      }
    }
  };

  const switchKit = async (chosenKit) => {
    if (chosenKit) {
      const response = await patchDeviceGroups(selectedSiteId, selectedKitId, selectedRoomId);
      console.log(response);
      await updateUnassignedKits(dispatch, selectedSiteId);
      navigate(`/home/market/${selectedSiteId}/property/${selectedRoomId}/activityLog`);
      window.location.reload();
    } else { setEmptyKitError(true); }
  };

  const chooseKit = (id, index) => {
    setSelectedKitIndex(index);
    setChosenKit(id);
    dispatch(updateSelectedKitId(id));
    setEmptyKitError(false);
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={updatingName}
      />
      { props.activityLog
            && (
            <>
              <header className="archive">
                {!archivedList?.includes(selectedRoomId)
                  ? <button id="archive-btn" className="primary-btn" onClick={() => setState({ archivePopup: true })}>Archive</button>
                  : (
                    <div>
                      <button id="assignKit-btn" className="primary-btn" onClick={() => setState({ assignKitPopup: true })}>Assign Kit</button>
                    </div>
                  )}
              </header>

              {archivedList?.includes(selectedRoomId)
                ? <div id="archive-banner">Archived</div>
                : null}

              {state.assignKitPopup
                ? (
                  <div id="assign-kit-popup" className="popup">
                    <div className="popup-content">
                      <h2 className="black">Assign Kit</h2>
                      <div className="confirm-message kit-list">
                        {unassignedKits.length > 0
                          ? (
                            <>
                              {unassignedKits?.map(
                                (kit, index) => (
                                  <li
                                    className={`kit-list-items ${slectedKitIndex === index ? 'pressed' : ''}`}
                                    key={index}
                                    onClick={() => chooseKit(kit.deviceGroupId, index)}
                                  >
                                    {kit.name}
                                  </li>
                                ),
                              )}
                              {emptyKitError && <p className="error">Please Choose a Kit</p>}
                            </>
                          )
                          : <span>No kits available</span> }

                      </div>
                      <div className="confirm-cancel-buttons">
                        <button className="primary-btn" onClick={() => switchKit(chosenKit)}>Assign</button>
                        <button className="primary-btn" onClick={() => setState({ assignKitPopup: false })}>Cancel</button>
                      </div>
                    </div>
                  </div>
                )
                : null}
            </>
            )}

      {state.archivePopup
        ? <ArchivePopup selectedRoomId={selectedRoomId} setState={setState} />
        : null}

      {viewEventPopup
        ? (
          <ViewEventPopup
            roomId={selectedRoomId}
            selectedSiteId={selectedSiteId}
            selectedEvent={props.selectedEvent}
          />
        )
        : null}

      { !props.activityLog
          && (
          <header className="archive">
            <button className="primary-btn" id="archive-btn" onClick={() => setState({ archivePopup: true })}>Archive</button>
          </header>
          )}

      <div className="right header-content">
        <div className="sub-header mb-1">
          <div className="left">
            <div>
              {editRoom
                ? (
                  <>
                    <input type="text" className="mb-1" id="new-room-name" autoFocus value={roomNewName} onChange={InputHandleChange} />
                    {roomError
                      ? (
                        <div className="error-message" id="room-error">
                          <FontAwesomeIcon icon={faTriangleExclamation} />
                          <span>
                            Error: A property with that name already exists.
                            Please choose a unique name.
                          </span>
                        </div>
                      )
                      : null}
                    <img src={edit} alt="edit" className="edit-btn" onClick={editRoomName} />
                    <img src={save} alt="save" className="edit-btn icon-filter-white" onClick={saveRoomName} />
                  </>
                )
                : (
                  <h2 className="mb-1 mr-02" id="room-name" onClick={editRoomName}>{`${selectedRoomName}  `}</h2>
                )}
              {props.addressTab && <AlertsToggle />}
            </div>
            {!props.activityLog
              && (
              <StatusBar
                activeAlertsCount={props.activeAlertsCount}
                deviceList={deviceList}
                selectedRoomId={selectedRoomId}
              />
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TabsHeader;
