import React from 'react';
import {
  Navigate, Route, BrowserRouter as Router, Routes,
} from 'react-router-dom';
import './App.css';
import ProtectedRoutes from './ProtectedRoutes';
import ErrorPage from './components/ErrorPage';
import HomeTab from './components/HomeTab';
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import PublicLiveView from './components/PublicLiveView';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage hide={false} />} />
        <Route path="/public/*" element={<PublicLiveView />} />
        <Route
          path="/home/*"
          element={(
            <ProtectedRoutes>
              <HomeTab />
            </ProtectedRoutes>
        )}
        />

        <Route path="/error-404" element={<ErrorPage />} />
        <Route path="*" element={<Navigate to="/error-404" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
