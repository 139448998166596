import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import stop from '../assets/stop.svg';
import extendTimeIcon from '../assets/extend-time.svg';
import { listSharedLinks, deleteSharedLink } from '../api/UserAPI';
import { formatDate, formatTime } from './FormatStuff';
import Popup from './Popup';
import SetTimeComponent from './SetTimeComponent';
import { updateSharedLinks } from '../redux/userInfo';

function SharedLinkInfo(props) {
  const dispatch = useDispatch();
  const links = useSelector((state) => state.userInfo.sharedLinks);
  const [showExtendTime, setShowExtendTime] = useState(false);
  const selectedRoomId = useSelector((state) => state.userInfo.selectedRoomId);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const sites = useSelector((state) => state.userInfo.sites);
  const [selectedLink, setSelectedLink] = useState(null);
  const selectedSite = sites ? sites.find((site) => site.siteId === selectedSiteId) : {};
  const timeZone = selectedSite?.config?.tz?.id;

  const checkLinks = async () => {
    const response = await listSharedLinks(selectedSiteId, selectedRoomId);
    dispatch(updateSharedLinks(response.links));
    props.setSharedLinks(response?.links?.length > 0);
  };

  const stopSharing = async (linkId) => {
    const response = await deleteSharedLink(selectedSiteId, selectedRoomId, linkId);
    if (response.link) checkLinks();
  };

  useEffect(() => {
    checkLinks();
  }, []);

  const extendTime = (link) => {
    setShowExtendTime(true);
    setSelectedLink(link);
  };

  return (
    <div>
      {showExtendTime && <Popup closePopup={() => setShowExtendTime(false)} title="Extend Share Time" child={<SetTimeComponent link={selectedLink} close={setShowExtendTime} />} />}
      {links && links.length > 0
        ? (
          <div>
            <h2 className="mb-1">Shared Feeds</h2>
            {links.map((link, index) => (
              <div className="shared-link-info-container mb-1" id={index}>
                <h4 className="mr-1">{link.name}</h4>
                <div className="flex-row">
                  <h4 className="mr-1 no-wrap">Expires At</h4>
                  <h5 className="white mr-1">{`${formatDate(link.expiresAt, timeZone)} ${formatTime(link.expiresAt, timeZone)}`}</h5>
                </div>
                <h5 className="white mr-1">{link.url}</h5>
                <div className="flex-row">
                  <img src={extendTimeIcon} alt="extendTime" className="share-info-icons mr-1 clickable" onClick={() => extendTime(link)} />
                  <img src={stop} alt="stop" className="share-info-icons mr-1 clickable" onClick={() => stopSharing(link.linkId)} />
                </div>
              </div>
            ))}
          </div>
        )
        : (
          <div />
        )}
    </div>
  );
}

export default SharedLinkInfo;
