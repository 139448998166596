import React, { useState } from 'react';
import copy from '../assets/copy.svg';
import SetTimeComponent from './SetTimeComponent';

function ShareLinkComponent() {
  const [showCopyText, setShowCopyText] = useState(false);
  const [link, setLink] = useState(null);
  const [showLink, setShowLink] = useState(false);
  const [email, setEmail] = useState('');

  const copyText = () => {
    navigator.clipboard.writeText(link.url);
    setShowCopyText(true);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div className="share-link-popup">
      {!showLink
      && (
        <SetTimeComponent create setLink={setLink} setShowLink={setShowLink} allowedTime={8} />
      )}

      {showLink
      && (
      <>
        {showCopyText && <h3 className="confirm-copy">Link Copied!</h3>}
        <div className="shared-link-container mb-1">
          <div className="shared-link">{link.url}</div>
          <img src={copy} alt="copy" className="clickable" onClick={copyText} />
        </div>
        <div className="shared-link-container mb-1 baseline">
          <input type="email" placeholder="Email Address" id="email" onChange={handleEmail} />
          <a className="primary-btn share-btn ml-05" href={`mailto:${email}?subject=Share%20video%20stream&body=This is the shared link: ${link.url}`}>Share by email</a>
        </div>
      </>
      )}
    </div>
  );
}
export default ShareLinkComponent;
