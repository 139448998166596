import React, {
  useState, forwardRef, useEffect, useRef,
} from 'react';
import play from '../assets/small-play.svg';
import pause from '../assets/small-pause.svg';
import mute from '../assets/mute.svg';
import unmute from '../assets/unmute.svg';
import fullscreen from '../assets/fullscreen.svg';
import rotateRight from '../assets/rotate-right.svg';

const VideoPlayer = forwardRef((props, ref) => {
  const [rotationDeg, setRotationDeg] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [vertical, setVertical] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const wrapperRef = useRef();
  const localRef = useRef();

  const getOrientation = () => {
    switch (props.orientation) {
      case 'NORMAL':
        setRotationDeg(0);
        setVertical(false);
        break;
      case 'ROTATED_LEFT':
        setRotationDeg(270);
        setVertical(true);
        break;
      case 'INVERTED':
        setRotationDeg(180);
        setVertical(false);
        break;
      case 'ROTATED_RIGHT':
        setRotationDeg(90);
        setVertical(true);
        break;
      default:
        setRotationDeg(0);
        setVertical(false);
    }
  };

  useEffect(() => {
    getOrientation();
  }, [props.orientation]);

  const handleTimeUpdate = () => {
    const videoElement = ref ? ref.current : localRef.current;
    setCurrentTime(videoElement.currentTime);
  };

  const togglePlayPause = () => {
    const videoElement = ref ? ref.current : localRef.current;
    isPlaying ? videoElement.pause() : videoElement.play(props.src);
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    const videoElement = ref ? ref.current : localRef.current;
    videoElement.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  const handleFullscreen = () => {
    const wrapperElement = wrapperRef.current;
    if (wrapperElement.requestFullscreen) {
      wrapperElement.requestFullscreen();
    } else if (wrapperElement.mozRequestFullscreen) {
      wrapperElement.mozRequestFullScreen();
    } else if (wrapperElement.webkitRequestFullscreen) {
      wrapperElement.webkitRequestFullscreen();
    } else if (wrapperElement.msRequestFullscreen) {
      wrapperElement.msRequestFullscreen();
    }
  };

  const formatVideoTime = (time) => {
    const hrs = Math.floor(time / 3600);
    const mins = Math.floor((time % 3600) / 60);
    const secs = Math.floor(time % 60);
    return `${hrs > 0 ? `${hrs}:` : ''}${mins < 10 ? `0${mins}` : mins}:${secs < 10 ? `0${secs}` : secs}`;
  };

  const rotate = () => {
    setRotationDeg((rotationDeg + 90) % 360);
    setVertical(!vertical);
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        /* eslint-disable no-param-reassign */
        ref ? ref.current.style.transform = `rotate(${rotationDeg}deg)` : localRef.current.style.transform = `rotate(${rotationDeg}deg)`;
      } else {
        ref ? ref.current.style.transform = '' : localRef.current.style.transform = '';
      }
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [rotationDeg]);

  return (
    <div
      className="video-container"
      style={
        {
          width: vertical ? '225px' : '', height: vertical ? '435px' : '', marginTop: vertical ? '50px' : '', marginBottom: vertical ? '-20px' : '5px',
        }
      }
    >
      <div
        className="video-player-wrapper"
        ref={wrapperRef}
        style={
          {
            transform: `rotate(${rotationDeg}deg)`, backgroundColor: '#000000',
          }
        }
      >
        { ref
          ? <video ref={ref} controls={false} onTimeUpdate={handleTimeUpdate} height="100%" width="100%" autoPlay={props.isConnected} />
          : <video id={props.id} ref={localRef} controls={false} onTimeUpdate={handleTimeUpdate} height="100%" width="100%" autoPlay={props.isConnected} loop />}
      </div>
      <div className="video-controls">
        {props.isConnected
        && (
          <>
            <img alt="play-pause" onClick={togglePlayPause} src={isPlaying ? pause : play} />
            <img onClick={toggleMute} alt="mute-unmute" className="ml-05" src={isMuted ? mute : unmute} />
            <span className="white">{formatVideoTime(currentTime)}</span>
          </>
        ) }
        <img src={rotateRight} alt="rotate-left" onClick={rotate} />
        <img onClick={handleFullscreen} src={fullscreen} alt="fullscreen" className="ml-05" />
      </div>
    </div>
  );
});

export default VideoPlayer;
