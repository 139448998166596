/* eslint-disable */
import React, { useState } from 'react';
import './LoginSignupPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import VerifyAccount from './VerifyAccount';
import { validateEmail } from './Update';
import { signup } from '../api/AuthAPI';

function SignupPage(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    firstName: '', lastName: '', email: '', password: '', password2: '',
  });
  const [errorPassMatch, setErrorPassMatch] = useState(false);
  const [errorPassLength, setErrorPassLength] = useState(false);
  const [acceptCheckBox, setAcceptCheckBox] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passVisible, setPassVisible] = useState({ pass1: false, pass2: false });
  const [emptyValueError, setEmptyValueError] = useState(false);
  const [HideSignup, setHidSingup] = useState(location?.state?.hide ? true : (!!props?.hide));
  const [signupError, setSignUpError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (event) => {
    const { id, value } = event.target;
    setUserData({ ...userData, [id]: value });
    if (id === 'password') userData.password.length < 7 ? setErrorPassLength(true) : setErrorPassLength(false);
  };

  const handleSubmit = async (event) => {
    if (userData.password === userData.password2) {
      setErrorPassMatch(false);
      if (Object.values(userData).every((value) => value !== '')) {
        event.preventDefault();
        setEmptyValueError(false);
        const response = await signup({
          given_name: userData.firstName,
          family_name: userData.lastName,
          email: userData.email,
          password: userData.password,
        });
        console.log(response);
        if (response.userSub) {
          setHidSingup(true);
        } else {
          setSignUpError(true);
          setErrorMessage(response.message);
        }
      } else {
        setEmptyValueError(true);
      }
    } else {
      setErrorPassMatch(true);
    }
  };

  const checkEmail = () => {
    validateEmail(userData.email) ? setEmailError(false) : setEmailError(true);
  };

  const toggleEye = (id, type) => {
   
    if (id === 'password') {
      setPassVisible({ ...passVisible, pass1: !passVisible.pass1 });
    } else if (id === 'password2') {
      setPassVisible({ ...passVisible, pass2: !passVisible.pass2 });
    }
    if (type === 'password') {
      document.getElementById(id).type = 'text';
    } else {
      document.getElementById(id).type = 'password';
    }
  };

  return (
    <>
      { !HideSignup && (
      <div className="outer-container">
        <div className="inner-container">
          <img src="https://cdn.shopify.com/s/files/1/0256/7900/3726/files/Logo_pink_horizontal-notagline.png?v=1654708500" alt="Swidget logo" />

          <div className="input-group">
            <div className="animated-field mb-15">
              <input type="text" id="firstName" autoComplete="off" placeholder=" " onChange={handleChange} required autoFocus />
              <label htmlFor="firstName" title="First Name" data-title="First Name" />
            </div>
            <div className="animated-field mb-15">
              <input type="text" id="lastName" autoComplete="off" placeholder=" " onChange={handleChange} required />
              <label htmlFor="lastName" title="Last Name" data-title="Last Name" />
            </div>
            <div className="animated-field mb-15">
              <input type="email" id="email" autoComplete="off" placeholder=" " onChange={handleChange} onBlur={checkEmail} required />
              <label htmlFor="email" title="Email Address" data-title="Email Address" />
              {emailError && <span className="error">Wrong email</span>}
            </div>

            <div className="animated-field mb-15">
              <input
                type="password"
                id="password"
                autoComplete="off"
                onChange={handleChange}
                placeholder=" "
                required
              />
              <label htmlFor="password" title="Password" data-title="Password" />
              {passVisible.pass1
                ? <FontAwesomeIcon icon={faEye} onClick={() => toggleEye("password", "password")} />
                : <FontAwesomeIcon icon={faEyeSlash} onClick={() => toggleEye("password", "text")} />}
              <div>
                { errorPassLength ? <span className="small-span error">Too short must be at least 8 characters</span> : <span className="small-span">Must be at least 8 characters long</span>}
              </div>
            </div>
            <div className="animated-field ">
              <input
                type="password"
                id="password2"
                onChange={handleChange}
                autoComplete="off"
                placeholder=" "
                required
              />
              <label htmlFor="password2" title="Re-Enter Password" data-title="Re-Enter Password" />
              {passVisible.pass2
                ? <FontAwesomeIcon icon={faEye} onClick={() => toggleEye("password2","password")} />
                : <FontAwesomeIcon icon={faEyeSlash} onClick={() => toggleEye("password2", "text")} />}
              {errorPassMatch && <span className="small-span error">Passwords don't match</span>}
            </div>
          </div>
          <div>
            <label className="switch mr-05">
              <input
                type="checkbox"
                checked={acceptCheckBox}
                onChange={(e) => setAcceptCheckBox(e.target.checked)}
              />
              <span className="slider" />
            </label>
            <span>
              I accept the
              <a href="https://www.swidget.com/en-ca/pages/terms-of-use" target="_blank" rel="noopener noreferrer" className="link">Terms and Conditions</a>
            </span>
          </div>
          {emptyValueError && <span className="small-span error">All Fields are required</span>}
          {signupError && <span className="small-span error">{errorMessage}</span>}
          <div className="btn-wrapper">
            <input
              type="submit"
              value="Create Account"
              className="swidget-pink-btn centered"
              disabled={!acceptCheckBox}
              onClick={handleSubmit}
            />
            <a className="link pink" onClick={() => navigate('/')}>Login</a>
          </div>

          <div className="copyright-wrapper">
            <p>Copyright © 2024 Swidget Corp.</p>
            <p>
              <a href="https://www.swidget.com/pages/terms-of-use" target="_blank" className="link" rel="noreferrer">Terms of Use</a>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              <a href="https://www.swidget.com/pages/privacy-policy" target="_blank" className="link" rel="noreferrer">Privacy Policy</a>
            </p>
          </div>

        </div>
      </div>
      )}
      {HideSignup && <VerifyAccount username={userData.email || location.state.email} />}
    </>

  );
}

export default SignupPage;
