import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateNotificationStatus } from './Update';
import { updateSitesList } from '../redux/userInfo';
import Toggle from './Toggle';

function EnableDisableNotification() {
  const dispatch = useDispatch();
  const sites = useSelector((state) => state.userInfo.sites);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const currentSite = sites.find((site) => site.siteId === selectedSiteId);
  const [siteAvailable, setSiteAvailable] = useState(false);
  const [emailToggle, setEmailToggle] = useState(true);
  const [pushToggle, setPushToggle] = useState(true);
  const [smsToggle, setSmsToggle] = useState(true);

  useEffect(() => {
    if (currentSite !== undefined) {
      setSiteAvailable(true);
      setEmailToggle(currentSite?.notification?.email?.enableAlerts);
      setPushToggle(currentSite?.notification?.push?.isEnabled);
      setSmsToggle(currentSite?.notification?.sms?.enableAlerts);
    }
  }, [currentSite]);

  const updateEmailNotification = async () => {
    const response = await updateNotificationStatus(
      selectedSiteId,
      !emailToggle,
      currentSite?.notification?.sms?.enableAlerts,
      currentSite?.notification?.push?.isEnabled,
    );
    setEmailToggle(!emailToggle);
    const updatedSitesList = sites.map((site) => {
      if (site.sitId === response.siteId) {
        return { ...site, notification: response.site.notification };
      }
      return site;
    });
    dispatch(updateSitesList(updatedSitesList));
  };

  const updatePushNotification = async () => {
    const response = await updateNotificationStatus(
      selectedSiteId,
      currentSite?.notification?.email?.enableAlerts,
      currentSite?.notification?.sms?.enableAlerts,
      !pushToggle,
    );
    setPushToggle(!pushToggle);
    const updatedSitesList = sites.map((site) => {
      if (site.sitId === response.siteId) {
        return { ...site, notification: response.site.notification };
      }
      return site;
    });
    dispatch(updateSitesList(updatedSitesList));
  };

  const updateSmsNotification = async () => {
    const response = await updateNotificationStatus(
      selectedSiteId,
      currentSite?.notification?.email?.enableAlerts,
      !smsToggle,
      currentSite?.notification?.push?.isEnabled,
    );

    setSmsToggle(!pushToggle);
    const updatedSitesList = sites.map((site) => {
      if (site.sitId === response.siteId) {
        return { ...site, notification: response.site.notification };
      }
      return site;
    });
    dispatch(updateSitesList(updatedSitesList));
  };

  return (
    <>
      {
        siteAvailable
        && (
        <div className="notification-control-container">
          <Toggle text="Enable Email Notifications" toggleState={emailToggle} toggleStateFunction={updateEmailNotification} />
          <br />
          <Toggle text="Enable sms Notifications" toggleState={smsToggle} toggleStateFunction={updateSmsNotification} />
          <br />
          <Toggle
            text="Enable Push Notifications"
            toggleState={pushToggle}
            toggleStateFunction={updatePushNotification}
          />
        </div>
        )
    }
      <div />
    </>
  );
}
export default EnableDisableNotification;
